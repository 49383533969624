$highlight: #cd131d !default;

body {
  font-family: 'oswald', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
}

h1, h2, h3, h4, h5, h6 {
  text-transform: uppercase;
}

a {
  color: #333;

  &:hover {
    color: $highlight;
    text-decoration: none;
  }

}

.highlight {
  color: $highlight;
}

#main-navigation {

  //font-family: 'oswald', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-size: 1.75rem;
  text-transform: uppercase;

  background-color: rgba(0, 0, 0, .7);
  color: #fff;

  &.navbar {
    padding-right: 5px;
  }

  .navbar-toggler {
    color: #fff;
  }

  a.navbar-brand, a.nav-link {
    color: #fff;
    font-size: inherit;
  }

  a.nav-link {
    padding-top: 0;
    padding-bottom: 0;
  }

  a.nav-link.active {
    color: $highlight;
  }

  .navbar-links {
    padding: 1rem 0;
    a {
      color: #fff;
    }
  }

  @media (min-width:768px) {
    padding-top: 2rem;
    padding-bottom: 2rem;
    background-color: transparent;
    transition: background-color 0.3s ease-in-out, padding 0.3s ease-in-out, font-size 0.3s ease-in-out;
    font-size: 1.75rem;

    a.navbar-brand {
      color: rgba(255, 255, 255, .7);
      font-weight: bold;
      margin-bottom: -12px;
      padding-bottom: 0;
    }

    a.nav-link, a.nav-link.active {
      color: #ccc;
    }

    .navbar-links {
      color: #ccc;
      font-size: 12px;
      text-align: right;
      padding: 0;

      a {
        color: #ccc;
        padding-left: .5rem;
        padding-right: .5rem;
      }
    }

    &.scrolling {
      background-color: rgba(0, 0, 0, .9);
      padding-top: 1rem;
      padding-bottom: 1rem;
      font-size: 1.75rem;

      .navbar-toggler {
        color: #fff;
      }

      a.navbar-brand, a.nav-link {
        color: #fff;
      }

      a.nav-link.active {
        color: $highlight;
      }

      .navbar-links {
        color: #fff;

        a {
          color: #fff;
        }
      }

    }

  }
}

header {
  height: 100vh;
  //background: url('/images/letters.png') no-repeat 60% bottom;
  //background-size: auto 250px;

  .carousel-inner {
    height: 100vh;

    .carousel-item {
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }

  }

}
